import React, {useCallback, useEffect, useState, Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import carouselCss from "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import style from "./style.scss";
import ChevronLeftIcon from '../../../travelerProfile/components/icons/ChevronLeftIcon';
import ChevronRightIcon from '../../../travelerProfile/components/icons/ChevronRightIcon';
export const enhance = compose(withStyles(style, carouselCss));


const Carousel = lazy(() =>
  import("react-responsive-carousel").then((mod) => ({ default: mod.Carousel }))
);

 function ImageCarousel() {
  const [images, setImages] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0); 
  const programImages = useSelector(state => state.programModify.programImages);

  useEffect(()=>{
      let selectedImages = [];
      if(programImages?.data?.length>0) {
        selectedImages = programImages.data.map((programImage)=>{
          return  {
            "original" : programImage.attributes.background,
            //"original" : `${programImage.attributes.original}`,
          }
        });
      }
      setImages(selectedImages);
    },[programImages])
  
    const next = useCallback(() => {
        console.log(prev, "call next");
        setCurrentSlide((prev) => prev + 1);
    }, []);
  
    const prev = useCallback(() => {
      console.log(prev, "call prev");
        setCurrentSlide((prev) => prev - 1);
    }, []);
  
    const updateCurrentSlide = useCallback((index) => {
      setCurrentSlide((prev) => (prev !== index ? index : prev));
    }, []);

  return (
    <div className={style['content']}>
      {images?.length>1 &&
        <>
          <button type="button" className={style.customPrevButton} onClick={prev} >
              <ChevronLeftIcon fillColor={"white"} />
          </button>
          <button type="button" className={style.customNextButton} onClick={next} >
              <ChevronRightIcon fillColor={"white"}  />
          </button>
          <div className={style.carouselWrapper}>
            <Suspense fallback={<div>Loading...</div>}>
              <Carousel
                showStatus={false}
                showArrows={false}
                selectedItem={currentSlide} 
                onChange={updateCurrentSlide}
                autoPlay={true}
                infiniteLoop
                lazyLoad={true}
                // dynamicHeight={true}

                // renderThumbs={() =>
                //   images.map((img, index) => (
                //     <img
                //       key={index}
                //       src={img.original}
                //       alt={`Thumbnail ${index + 1}`}
                //       style={{ width: "80px", height: "60px", objectFit: "cover" }}
                //     />
                //   ))
                // }
              >
                  {images.map((item)=>(
                      <div>
                      <img 
                        src={item.original} 
                        // style={{ width: "fit-content", height: "580px", objectFit: "cover" }} 
                      />
                      </div>
                  ))}
              </Carousel>
            </Suspense>
          </div>
        </>
      }
      {images?.length===1 &&
        <div className={style.programImageWrapper}>
          <div className={style.imageContainer} >
            <img src={images[0]?.original} />
          </div>
        </div>
      }
    </div>
  );
}

export default enhance(ImageCarousel);