exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Sq44{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;gap:25px;width:90%;margin:auto;position:relative}._4gxQx{top:40%;bottom:0;margin-top:0;position:absolute;height:30px;left:1px;border-radius:2.343px;border:.732px solid #d74b73;border:.732px solid var(--Theme-Org_Secondary,#d74b73);background:#d74b73;background:var(--Theme-Org_Secondary,#d74b73)}@media (max-width:768px){._4gxQx{left:-9px;top:25%}}._1oqHV{top:40%;bottom:0;margin-top:0;position:absolute;right:0;height:30px;border-radius:2.343px;border:.732px solid #d74b73;border:.732px solid var(--Theme-Org_Secondary,#d74b73);background:#d74b73;background:var(--Theme-Org_Secondary,#d74b73)}@media (max-width:768px){._1oqHV{right:-9px;top:25%}}._2olpC{width:90%}._2olpC,._2tZRu{height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;margin:auto}._3Jmxd{display:-ms-flexbox;display:flex;margin:auto;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}@media (max-width:768px){._3Jmxd{width:100%}}._2tZRu img{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:600px;-o-object-fit:cover;object-fit:cover}@media (max-width:768px){._2tZRu img{height:200px;width:100%}}", ""]);

// exports
exports.locals = {
	"content": "_3Sq44",
	"customPrevButton": "_4gxQx",
	"customNextButton": "_1oqHV",
	"carouselWrapper": "_2olpC",
	"programImageWrapper": "_2tZRu",
	"imageContainer": "_3Jmxd"
};