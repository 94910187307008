import React, { useState } from 'react';
import { compose } from 'recompose';
import propTypes from 'prop-types';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Headline from '../../../../components/library/headline';
import Paragraph from '../../../../components/library/paragraph';
import ViaModal from '../../../../components/library/viaModal';
import ActivityForm from '../../forms/activityForm';
import RemoveDetail from '../../modals/removeDetail';
import ReinstateDetail from '../../modals/reinstateDetail';
import { getPlan } from '../../../../../actions/plans';
import sActivityItemView from './activityItemView.scss';
import ViaIcon from '../../ViaIcon';

export const enhance = compose(withStyles(sActivityItemView));

function ActivityItemView(props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.profile.currentUser);
  const permissions = useSelector(state => state.profile.permissions);
  const [showActivity, setShowActivity] = useState(false);
  const [removeDetail, setRemoveDetail] = useState(false);
  const [showReinstateDetail, setShowReinstateDetail] = useState(false);
  const activity = {
    tp_activity_type_id: props.detail.tp_activity_type_id,
    tp_activity_type_name: props.detail.tp_activity_type_name,
    nickname: props.detail.nickname,
    provider_name: props.detail.provider_name,
    confirmation_number: props.detail.confirmation_number,
    provider_contact_name: props.detail.provider_contact_name,
    start_date: moment(props.detail.start_date).format('MMM D, YYYY'),
    start_time: props.detail.start_time,
    start_tp_time_zone_id: '',
    end_date: moment(props.detail.end_date).format('MMM D, YYYY'),
    end_time: props.detail.end_time,
    end_tp_time_zone_id: '',
    notes: props.detail.notes,
    group: props.detail.group,
    removed: props.detail.removed,
    force_add: props.detail.force_add,
    formatted_address: _get(props, 'detail.location.formatted_address') || '',
    timezone: _get(props, 'detail.location.time_zone') || '',
  };

  return (
    <>
      <div className={sActivityItemView['activity-item']}>
        <div className={sActivityItemView.soloGroupContainer}>
          <Paragraph>
            {activity.removed ? 'Removed ' : ''}
            {activity.group ? 'With the Group' : 'My Detail'}
          </Paragraph>
          <ViaIcon
            name={activity.group ? 'group' : 'solo'}
            color="darkText"
            size={activity.group ? 'smallPlusW10' : 'small'}
          />
        </div>
        <div className={activity.removed ? sActivityItemView.headerRemoved : sActivityItemView.headerActive}>
          <ViaIcon activityId={Number(activity.tp_activity_type_id)} />
          <div style={{
                          fontSize:"16px",
                          lineHeight : "24px",
                          fontFamily : "IntroBoldItalic,sans-serif",
                          color:"#fff",
                          flex : "1 1"
                      }}>
            {activity.nickname ? activity.nickname : activity.provider_name}
          </div>
          <div className={sActivityItemView.action}>
            {!props.hideEdit && (
              <a
                href='#'
                role='button'
                aria-label='Edit'
                className={sActivityItemView['action-item']}
                onClick={(e) => {
                  e.preventDefault();
                  setShowActivity(true);
                }}
              >
                <FontAwesomeIcon icon="pencil-alt" color="white" size="sm" />
                <Paragraph>Edit</Paragraph>
              </a>
            )}
            {(currentUser['client_user_role?'] &&
              permissions.travel_plans_settings.includes('delete_travel_plan_and_itinerary')) ||
            currentUser['is_traveler?'] ? (
              !props.hideRemove ? (
                !activity.removed ? (
                  <a
                    href='#'
                    role='button'
                    aria-label='Remove'
                    className={sActivityItemView['action-item']}
                    onClick={(e) => {
                      e.preventDefault();
                      setRemoveDetail(true);
                    }}
                  >
                    <Paragraph> x Remove</Paragraph>
                  </a>
                ) : (
                  <a
                    href='#'
                    role='button'
                    aria-label='Reinstate'
                    className={sActivityItemView['action-item']}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowReinstateDetail(true);
                    }}
                  >
                    <FontAwesomeIcon icon="plus" color="white" size="sm" />
                    <Paragraph>Reinstate</Paragraph>
                  </a>
                )
              ) : null
            ) : null}
          </div>
        </div>
        <div className={sActivityItemView.itineraryContainer}>
          <div className={sActivityItemView.column}>
            <div className={sActivityItemView.row}>
              <div className={sActivityItemView.item}>
                <Paragraph size="medium">Start Date</Paragraph>
                <div className={sActivityItemView.innerRow}>
                  <Paragraph size="small">{activity.start_date}</Paragraph>
                </div>
              </div>
            </div>
            <div className={sActivityItemView.row}>
              <div className={sActivityItemView.item}>
                <Paragraph size="medium">Start Time</Paragraph>
                <div className={sActivityItemView.innerRow}>
                  <Paragraph size="small">{`${activity.start_time} ${activity.timezone}`}</Paragraph>
                </div>
              </div>
            </div>
            <div className={sActivityItemView.row}>
              <div className={sActivityItemView.item}>
                <Paragraph size="medium">Notes</Paragraph>
                <div className={sActivityItemView.innerRow}>
                  <Paragraph size="small">{activity.notes}</Paragraph>
                </div>
              </div>
            </div>
          </div>

          <div className={sActivityItemView.borderedColumnLeft}>
            <div className={sActivityItemView.item}>
              <Paragraph size="medium">Confirmation #</Paragraph>
              <div className={sActivityItemView.innerRow}>
                <Paragraph size="small" >{activity.confirmation_number}</Paragraph>
              </div>
            </div>
          </div>

          <div className={sActivityItemView.borderedColumnRight}>
            <div className={sActivityItemView.item}>
              <Paragraph size="medium">Provider Name</Paragraph>
              <div className={sActivityItemView.innerRow}>
                <Paragraph size="small">{activity.provider_name}</Paragraph>
              </div>
            </div>
            <div className={sActivityItemView.item}>
              <Paragraph size="medium">Contact Name</Paragraph>
              <div className={sActivityItemView.innerRow}>
                <Paragraph size="small">{activity.provider_contact_name}</Paragraph>
              </div>
            </div>
            <div className={sActivityItemView.item}>
              <Paragraph size="medium">Provider Address</Paragraph>
              <div className={sActivityItemView.innerRow}>
                <Paragraph size="small">{activity.formatted_address}</Paragraph>
              </div>
            </div>
          </div>

          <div className={sActivityItemView.column}>
            <div className={sActivityItemView.row}>
              <div className={sActivityItemView.item}>
                <Paragraph size="medium">End Date</Paragraph>
                <div className={sActivityItemView.innerRow}>
                  <Paragraph size="small">{activity.end_date}</Paragraph>
                </div>
              </div>
            </div>
            <div className={sActivityItemView.row}>
              <div className={sActivityItemView.item}>
                <Paragraph size="medium">End Time</Paragraph>
                <div className={sActivityItemView.innerRow}>
                  <Paragraph size="small">{`${activity.end_time} ${activity.timezone}`}</Paragraph>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViaModal
        showing={showActivity}
        onClose={() => {
          dispatch(getPlan(props.planId));
          setShowActivity(false);
        }}
        headerText="Edit Activity"
      >
        <ActivityForm
          onClose={() => {
            dispatch(getPlan(props.planId));
            setShowActivity(false);
          }}
          isEdit
          details={props.detail}
          itemId={props.detail.id}
          planId={props.planId}
          group={props.detail.group}
        />
      </ViaModal>
      <RemoveDetail
        show={removeDetail}
        onClose={() => setRemoveDetail(false)}
        detailId={props.detail.id.toString()}
        type="plan_activities"
        planId={props.planId}
        plans_user_id={props.plans_user_id}
        groupRemove={props.groupRemove}
      />
      <ReinstateDetail
        show={showReinstateDetail}
        onClose={() => setShowReinstateDetail(false)}
        detailId={props.detail.id.toString()}
        type="plan_activities"
        planId={props.planId}
        plans_user_id={props.plans_user_id}
        groupReinstate={props.groupReinstate}
      />
    </>
  );
}

ActivityItemView.propTypes = {
  detail: propTypes.object.isRequired,
  planId: propTypes.string.isRequired,
  hideEdit: propTypes.bool,
  hideRemove: propTypes.bool,
  plans_user_id: propTypes.number,
  groupRemove: propTypes.bool,
  groupReinstate: propTypes.bool,
};

ActivityItemView.defaultProps = {
  hideEdit: false,
  hideRemove: false,
  plans_user_id: null,
  groupRemove: false,
  groupReinstate: false,
};

export default enhance(ActivityItemView);
