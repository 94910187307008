import * as yup from 'yup';
import _get from 'lodash/get';

export const FIELD_NAMES = {
  city: 'address.city',
  institution_type: 'client_account_info.institution_type',
  country: 'address.country',
  department: 'client_account_info.department',
  description: 'client_account_info.description',
  participants_goal: 'client_account_info.participants_goal',
  name: 'client_account_info.name',
  non_profit: 'client_account_info.non_profit',
  org_type: 'client_account_info.org_type',
  phone_country_code: 'client_account_info.phone_country_code',
  phone_number: 'client_account_info.phone_number',
  public_org: 'client_account_info.public_org',
  secondary_street: 'address.secondary_street',
  participants_sent: 'client_account_info.participants_sent',
  show_intake: 'client_account_info.show_intake',
  show_program_match: 'client_account_info.show_program_match',
  state: 'address.state',
  street: 'address.street',
  subdomain: 'client_account_info.subdomain',
  org_timezone: 'client_account_info.org_timezone',
  zip: 'address.zip',
  allow_traveler_deferral: 'client_account_info.allow_traveler_deferral',
  allow_traveler_sign_up: 'client_account_info.allow_traveler_sign_up',
  limit_applications_by_timeframe: 'client_account_info.limit_applications_by_timeframe',
  //allow_student_universe_mailer: 'client_account_info.allow_student_universe_mailer',
  enable_active_term_name_filtering: 'client_account_info.enable_active_term_name_filtering',
  application_statuses: 'request_to_withdraw_setting.application_statuses',
  instruction: 'request_to_withdraw_setting.instruction',
  request_to_withdraw_setting_active: 'request_to_withdraw_setting.active',
  show_sign_in_instruction: 'client_account_info.show_sign_in_instruction',
  sign_in_instruction: 'client_account_info.sign_in_instruction',
};

export const schema = yup.object().shape({
  // application_statuses: yup.string().required('Application status is required'),
});

export const buildDefaultValues = (
  organizationDefaultData,
  addressDefaultData,
  requestToWithdrawDefaultData,
  org_name,
  allow_traveler_sign_up,
) => {
  const subdomain = `${organizationDefaultData.subdomain}.via-trm.com`;

  const client_account_info = {
    client_account_info: {
      participants_goal: _get(organizationDefaultData, ['participants_goal']) || '',
      participants_sent: _get(organizationDefaultData, ['participants_sent']) || '',
      phone_country_code: _get(organizationDefaultData, ['phone_country_code']) || '',
      phone_number: _get(organizationDefaultData, ['phone_number']) || '',
      department: _get(organizationDefaultData, ['department']) || '',
      org_type: _get(organizationDefaultData, ['org_type']) || '',
      institution_type: _get(organizationDefaultData, ['institution_type']) || '',
      non_profit: _get(organizationDefaultData, ['non_profit']) || false,
      public_org: _get(organizationDefaultData, ['public_org']) || '',
      theme_code: _get(organizationDefaultData, ['theme_code']) || '7',
      theme_color_dark: _get(organizationDefaultData, ['theme_color_dark']) || '#043544',
      theme_color_light: _get(organizationDefaultData, ['theme_color_light']) || '#56a3ba',
      theme_color_accent: _get(organizationDefaultData, ['theme_color_accent']) || '#df235d',
      logo: _get(organizationDefaultData, ['logo']) || [],
      subdomain: subdomain || '',
      description: _get(organizationDefaultData, ['description']) || '',
      alias_enrollment: _get(organizationDefaultData, ['alias_enrollment']) || '',
      alias_favorite: _get(organizationDefaultData, ['alias_favorite']) || '',
      alias_favorites: _get(organizationDefaultData, ['alias_favorites']) || '',
      alias_unfavorite: _get(organizationDefaultData, ['alias_unfavorite']) || '',
      alias_program: _get(organizationDefaultData, ['alias_program']) || '',
      alias_programs: _get(organizationDefaultData, ['alias_programs']) || '',
      alias_traveler: _get(organizationDefaultData, ['alias_traveler']) || '',
      alias_travelers: _get(organizationDefaultData, ['alias_travelers']) || '',
      alias_traveling: _get(organizationDefaultData, ['alias_traveling']) || '',
      org_timezone: _get(organizationDefaultData, ['org_timezone']) || false,
      show_intake: _get(organizationDefaultData, ['show_intake']) || false,
      show_program_match: _get(organizationDefaultData, ['show_program_match']) || false,
      name: org_name || '',
      use_custom_aliases: _get(organizationDefaultData, ['use_custom_aliases']) || false,
      allow_traveler_deferral: _get(organizationDefaultData, ['allow_traveler_deferral']) || false,
      allow_traveler_sign_up: allow_traveler_sign_up || false,
      limit_applications_by_timeframe: _get(organizationDefaultData, ['limit_applications_by_timeframe']) || false,
      //allow_student_universe_mailer: _get(organizationDefaultData, ['allow_student_universe_mailer']) || false,
      enable_active_term_name_filtering: _get(organizationDefaultData, ['enable_active_term_name_filtering']) || false,
      show_sign_in_instruction: _get(organizationDefaultData, ['show_sign_in_instruction']) || false,
      sign_in_instruction: _get(organizationDefaultData, ['sign_in_instruction']) || "",
    },
    address: {
      state: _get(addressDefaultData, ['state']) || '',
      street: _get(addressDefaultData, ['street']) || '',
      secondary_street: _get(addressDefaultData, ['secondary_street']) || '',
      city: _get(addressDefaultData, ['city']) || '',
      zip: _get(addressDefaultData, ['zip']) || '',
      country: _get(addressDefaultData, ['country']) || '',
    },

    request_to_withdraw_setting: {
      application_statuses: _get(requestToWithdrawDefaultData, ['application_statuses']) || [],
      instruction: _get(requestToWithdrawDefaultData, ['instruction']) || '',
      active: _get(requestToWithdrawDefaultData, ['active']) || false,
    },
  };

  return client_account_info;
};
