exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3wUkw{padding:30px 45px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;gap:30px}._31JvX{font-family:Open Sans;font-size:16px;font-weight:400;line-height:22.4px;letter-spacing:.005em;text-align:center}._3OQ7Z{padding:0}._3XoHZ{border-top:1px solid #999;border-top:1px solid var(--gray-medium2,#999);-ms-flex-pack:end;justify-content:end;padding:26px 20px;-ms-flex-align:center;align-items:center;gap:8px;-ms-flex-item-align:stretch;align-self:stretch}._2ZlWJ,._3XoHZ{display:-ms-flexbox;display:flex}._2ZlWJ{gap:20px}._1Jj2d{display:-ms-flexbox;display:flex;padding:6px 12px;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;gap:8px;border-radius:4px;border:1px solid #446372;border:1px solid var(--Theme-Primary,#446372);background:rgba(191,41,41,0);background:var(--HitBox,rgba(191,41,41,0));color:#446372;color:var(--Theme-Primary,#446372);font-family:Open Sans;font-size:16px;font-style:normal;font-weight:700;line-height:150%}", ""]);

// exports
exports.locals = {
	"container": "_3wUkw",
	"text": "_31JvX",
	"modalBody": "_3OQ7Z",
	"footer": "_3XoHZ",
	"buttonContainer": "_2ZlWJ",
	"cancelButton": "_1Jj2d"
};