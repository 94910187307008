import React, { lazy, Suspense } from 'react';
import { Controller } from 'react-hook-form';
import DropdownSelect from '../../../../../../styledComponents/styles/DropdownSelect.styled';
import moment from 'moment-timezone';
import styled from 'styled-components';
import TextInput from '../../../../../../styledComponents/styles/TextInput.styled';
import TextInputArea from '../../../../../../styledComponents/styles/TextInputArea.styled';
import Toggle from '../../../../../../styledComponents/styles/Toggle.styled';
import { FIELD_NAMES } from './form.properties';
import TextAreaExpanding from '../../../../../../styledComponents/styles/TextAreaExpanding.styed';
import ToggleCheckBoxIcon from '../../../../../travelerProfile/components/icons/ToggleCheckBoxIcon';
const ToggleContainerCheckbox = styled.a``;
const ToggleContainer = styled.div`
  flex-direction: column;
  display: flex;
  padding-top: 5px;
`;

const ToggleWrapper = styled.div`
  display: inline-flex;
  align-items: baseline;
`;

const EditTextArea = lazy(() => import('../../../../../../styledComponents/styles/EditTextArea.styled'));

const collegeTypeOptions = [
  { value: null, label: '' },
  { value: 'Two Year College', label: 'Two Year College' },
  { value: 'Community College', label: 'Community College' },
  { value: 'Four Year College', label: 'Four Year College' },
  { value: 'College / University', label: 'College / University' },
];

const goalOptions = [
  { value: null, label: '' },
  { value: '0', label: '0-100' },
  { value: '101', label: '101-200' },
  { value: '201', label: '201-500' },
  { value: '501', label: '501-800' },
  { value: '801', label: '801-1000' },
  { value: '1001', label: '1001-2000' },
  { value: '2001', label: '2001-5000' },
  { value: '5000', label: '5000+' },
];

const nonProfitOptions = [
  { value: null, label: '' },
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
  { value: 'not_sure', label: 'Not Sure' },
];

const organizationTypeOptions = [
  { value: null, label: '' },
  {
    value: 'College / University',
    label: 'College / University',
  },
  {
    value: 'Third Party',
    label: 'Third Party Program Provider',
  },
  { value: 'Secondary School', label: 'Secondary School' },
  { value: 'Travel Company', label: 'Travel Company' },
  { value: 'Other', label: 'Other' },
];

const publicOrgOptions = [
  { value: null, label: '' },
  { value: true, label: 'Public' },
  { value: false, label: 'Private' },
];

const travelerOptions = [
  { value: null, label: '' },
  { value: '0', label: '0-100' },
  { value: '101', label: '101-200' },
  { value: '201', label: '201-500' },
  { value: '501', label: '501-800' },
  { value: '801', label: '801-1000' },
  { value: '1001', label: '1001-2000' },
  { value: '2001', label: '2001-5000' },
  { value: '5000', label: '5000+' },
];

export const NameInput = ({ control, errors, organization, userPermissions, isSaving }) => {
  return (
    <Controller
      control={control}
      name={FIELD_NAMES['name']}
      render={({ field: { ref, ...rest } }) => (
        <TextInput
          icon={'LockClosed'}
          id={FIELD_NAMES['name']}
          label={`Organization Name`}
          required
          type={'text'}
          {...rest}
          disabled={true}
        />
      )}
    />
  );
};

export const SubdomainInput = ({ control, errors, userPermissions, isSaving }) => {
  return (
    <Controller
      control={control}
      name={FIELD_NAMES['subdomain']}
      render={({ field: { ref, ...rest } }) => (
        <TextInput
          disabled
          icon={'LockClosed'}
          label={`Subdomain URL`}
          type={'text'}
          required
          id={FIELD_NAMES['subdomain']}
          {...rest}
        />
      )}
    />
  );
};

export const DepartmentInput = ({ control, errors, organization, userPermissions, isSaving }) => {
  return (
    <Controller
      control={control}
      name={FIELD_NAMES['department']}
      render={({ field: { ref, ...rest } }) => (
        <TextInput
          label={`Public Department Name`}
          placeholder={'Enter department name'}
          type={'text'}
          icon={!userPermissions.canEdit || isSaving ? 'LockClosed' : ''}
          disabled={!userPermissions.canEdit || isSaving}
          key={'department'}
          id={FIELD_NAMES['department']}
          {...rest}
        />
      )}
    />
  );
};

export const PhoneNumberInput = ({ control, errors, organization, userPermissions, isSaving }) => {
  return (
    <Controller
      control={control}
      name={FIELD_NAMES['phone_number']}
      render={({ field: { ref, ...rest } }) => (
        <TextInput
          label={`Organization Phone Number`}
          placeholder={'Enter organization phone number'}
          icon={!userPermissions.canEdit || isSaving ? 'LockClosed' : ''}
          type={'text'}
          disabled={!userPermissions.canEdit || isSaving}
          value={`${organization.phone_country_code} ${organization.phone_number}`}
          id={FIELD_NAMES['phone_number']}
          {...rest}
        />
      )}
    />
  );
};

export const StreetInput = ({ control, errors, address, userPermissions, isSaving }) => {
  return (
    <Controller
      control={control}
      name={FIELD_NAMES['street']}
      render={({ field: { ref, ...rest } }) => (
        <TextInput
          label={`Organization Address 1`}
          disabled={!userPermissions.canEdit || isSaving}
          icon={!userPermissions.canEdit || isSaving ? 'LockClosed' : ''}
          placeholder={'Enter organization address 1'}
          type={'text'}
          id={FIELD_NAMES['street']}
          {...rest}
        />
      )}
    />
  );
};
export const SecondaryStreetInput = ({ control, errors, address, userPermissions, isSaving }) => {
  return (
    <Controller
      control={control}
      name={FIELD_NAMES['secondary_street']}
      render={({ field: { ref, ...rest } }) => (
        <TextInput
          label={`Organization Address 2`}
          placeholder={'Enter organization address 2'}
          icon={!userPermissions.canEdit || isSaving ? 'LockClosed' : ''}
          type={'text'}
          disabled={!userPermissions.canEdit || isSaving}
          id={FIELD_NAMES['secondary_street']}
          {...rest}
        />
      )}
    />
  );
};

export const StateInput = ({ control, errors, address, userPermissions, isSaving }) => {
  return (
    <Controller
      control={control}
      name={FIELD_NAMES['state']}
      render={({ field: { ref, ...rest } }) => (
        <TextInput
          label={`State / Province / Region`}
          placeholder={'Enter organization state / province / region'}
          icon={!userPermissions.canEdit || isSaving ? 'LockClosed' : ''}
          type={'text'}
          disabled={!userPermissions.canEdit || isSaving}
          id={FIELD_NAMES['state']}
          {...rest}
        />
      )}
    />
  );
};
export const CityInput = ({ control, errors, address, userPermissions, isSaving }) => {
  return (
    <Controller
      control={control}
      name={FIELD_NAMES['city']}
      render={({ field: { ref, ...rest } }) => (
        <TextInput
          label={`City`}
          placeholder={'Enter organization city'}
          icon={!userPermissions.canEdit || isSaving ? 'LockClosed' : ''}
          type={'text'}
          disabled={!userPermissions.canEdit || isSaving}
          id={FIELD_NAMES['city']}
          {...rest}
        />
      )}
    />
  );
};

export const ZipInput = ({ control, errors, address, userPermissions, isSaving }) => {
  return (
    <Controller
      control={control}
      name={FIELD_NAMES['zip']}
      render={({ field: { ref, ...rest } }) => (
        <TextInput
          label={`Zip / Postal Code`}
          placeholder={'Enter organization zip / postal code'}
          icon={!userPermissions.canEdit || isSaving ? 'LockClosed' : ''}
          type={'text'}
          disabled={!userPermissions.canEdit || isSaving}
          id={FIELD_NAMES['zip']}
          {...rest}
        />
      )}
    />
  );
};

export const CountryInput = ({ control, errors, address, userPermissions, isSaving }) => {
  return (
    <Controller
      control={control}
      name={FIELD_NAMES['country']}
      render={({ field: { ref, ...rest } }) => (
        <TextInput
          label={`Country`}
          placeholder={'Enter organization country'}
          icon={!userPermissions.canEdit || isSaving ? 'LockClosed' : ''}
          type={'text'}
          disabled={!userPermissions.canEdit || isSaving}
          id={FIELD_NAMES['country']}
          {...rest}
        />
      )}
    />
  );
};

export const OrganizationDescriptionInput = ({ control, userPermissions, organization, isSaving, setValue }) => {
  return (
    <Controller
      control={control}
      name={FIELD_NAMES['description']}
      render={({ field: { onChange, defaultValue, value, name, ref, ...rest } }) => (
        <Suspense fallback={<div>Loading...</div>}>
          <EditTextArea
            readOnly={!userPermissions.canEdit || isSaving}
            id={FIELD_NAMES['description']}
            value={value}
            label={'Organization Description'}
            placeholder={'Enter organization description'}
            onChange={value => {
              setValue(FIELD_NAMES['description'], value, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              });
              onChange(value);
            }}
            {...rest}
          />
        </Suspense>
      )}
    />
  );
};

// export const OrganizationTypeDropdown = ({ control, errors, organization, setValue, userPermissions, isSaving }) => {
//   const orgType = organizationTypeOptions.filter(option => option.value === organization.org_type);

//   return (
//     <Controller
//       control={control}
//       name={FIELD_NAMES['org_type']}
//       render={({ field: { onChange, defaultValue, value, name, ref, ...rest } }) => (
//         <DropdownSelect
//           errorMsg={''}
//           label={`Organization Type`}
//           placeholder={'Select type of organization'}
//           defaultValue={orgType}
//           isDisabled={!userPermissions.canEdit || isSaving}
//           options={organizationTypeOptions}
//           id={FIELD_NAMES['org_type']}
//           onChange={value => {
//             setValue(FIELD_NAMES['org_type'], value, {
//               shouldValidate: true,
//               shouldDirty: true,
//               shouldTouch: true,
//             });
//             onChange(value);
//           }}
//           {...rest}
//         />
//       )}
//     />
//   );
// };

export const ApplicationStatusDropdown = ({
  control,
  errors,
  request_to_withdraw_setting,
  setValue,
  userPermissions,
  isSaving,
  applicationStatusOptions,
  applicationStatusError,
}) => {
  return (
    <Controller
      control={control}
      name={FIELD_NAMES['application_statuses']}
      render={({ field: { onChange, defaultValue, value, name, ref, ...rest } }) => (
        <DropdownSelect
          errorMsg={applicationStatusError || ''}
          label={`Application Statuses`}
          placeholder={'Select an application status'}
          // value={applicationStatusOptions.filter(i =>
          //   request_to_withdraw_setting.application_statuses.includes(i.value),
          // )}

          defaultValue={applicationStatusOptions.filter(i =>
            request_to_withdraw_setting.application_statuses.includes(i.value),
          )}
          isDisabled={!userPermissions.canEdit || isSaving}
          options={applicationStatusOptions}
          id={FIELD_NAMES['application_statuses']}
          isMulti={true}
          onChange={value => {
            setValue(FIELD_NAMES['application_statuses'], value, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            });
            onChange(value);
          }}
          required
          {...rest}
        />
      )}
    />
  );
};

export const InstructionTextArea = ({
  control,
  errors,
  request_to_withdraw_setting,
  userPermissions,
  isSaving,
  customAliases,
}) => {
  return (
    <>
      <Controller
        control={control}
        name={FIELD_NAMES['instruction']}
        render={({ field: { ref, ...rest } }) => (
          <TextInputArea
            label={`Additional Request to Withdraw ${customAliases.alias_traveler} Instructions`}
            placeholder={'Enter additional instructions here'}
            icon={!userPermissions.canEdit || isSaving ? 'LockClosed' : ''}
            disabled={!userPermissions.canEdit || isSaving}
            key={'instruction'}
            id={FIELD_NAMES['instruction']}
            rows={4}
            charLimit={500}
            {...rest}
          />
        )}
      />
    </>
  );
};

export const CheckBoxStatus = ({
  control,
  errors,
  request_to_withdraw_setting,
  userPermissions,
  isSaving,
  onCheckboxChange,
  setValue,
  getValues,
  checked,
}) => {
  return (
    <>
      <Controller
        control={control}
        name={FIELD_NAMES['request_to_withdraw_setting_active']}
        render={({ field: { ref, ...rest } }) => (
          <input
            type="checkbox"
            id={FIELD_NAMES['request_to_withdraw_setting_active']}
            disabled={!userPermissions.canEdit || isSaving}
            key={'request_to_withdraw_setting_active'}
            checked={checked}
            {...rest}
            onChange={e => {
              setValue(FIELD_NAMES['request_to_withdraw_setting_active'], e.target.checked, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              });
              onCheckboxChange(e);
            }}
          />
        )}
      />
    </>
  );
};

// export const CollegeTypeDropdown = ({ control, errors, organization, userPermissions, setValue, isSaving }) => {
//   const collegeType = collegeTypeOptions.filter(option => option.value === organization.institution_type);

//   return (
//     <Controller
//       control={control}
//       name={FIELD_NAMES['institution_type']}
//       render={({ field: { onChange, defaultValue, value, name, ref, ...rest } }) => (
//         <DropdownSelect
//           errorMsg={''}
//           label={`College Type`}
//           placeholder={'Select college type'}
//           defaultValue={collegeType}
//           isDisabled={!userPermissions.canEdit || isSaving}
//           options={collegeTypeOptions}
//           id={FIELD_NAMES['institution_type']}
//           onChange={value => {
//             setValue(FIELD_NAMES['institution_type'], value, {
//               shouldValidate: true,
//               shouldDirty: true,
//               shouldTouch: true,
//             });
//             onChange(value);
//           }}
//           {...rest}
//         />
//       )}
//     />
//   );
// };

// export const NonProfitDropdown = ({ control, errors, organization, userPermissions, setValue, isSaving }) => {
//   const nonProfit = nonProfitOptions.filter(option => option.value === organization.non_profit);
//   return (
//     <Controller
//       control={control}
//       name={FIELD_NAMES['non_profit']}
//       render={({ field: { onChange, defaultValue, value, name, ref, ...rest } }) => (
//         <DropdownSelect
//           errorMsg={''}
//           label={`Non-profit?`}
//           placeholder={'Select non-profit / for profit status'}
//           defaultValue={nonProfit}
//           isDisabled={!userPermissions.canEdit || isSaving}
//           options={nonProfitOptions}
//           id={FIELD_NAMES['non_profit']}
//           onChange={value => {
//             setValue(FIELD_NAMES['non_profit'], value, {
//               shouldValidate: true,
//               shouldDirty: true,
//               shouldTouch: true,
//             });
//             onChange(value);
//           }}
//           {...rest}
//         />
//       )}
//     />
//   );
// };

// export const PublicOrganizationDropdown = ({ control, errors, organization, userPermissions, setValue, isSaving }) => {
//   const publicOrg = publicOrgOptions.filter(option => option.value === organization.public_org);

//   return (
//     <Controller
//       control={control}
//       name={FIELD_NAMES['public_org']}
//       render={({ field: { onChange, defaultValue, value, name, ref, ...rest } }) => (
//         <DropdownSelect
//           errorMsg={''}
//           label={`Public / Private`}
//           placeholder={'Select public / private status '}
//           defaultValue={publicOrg}
//           isDisabled={!userPermissions.canEdit || isSaving}
//           options={publicOrgOptions}
//           id={FIELD_NAMES['public_org']}
//           onChange={value => {
//             setValue(FIELD_NAMES['public_org'], value, {
//               shouldValidate: true,
//               shouldDirty: true,
//               shouldTouch: true,
//             });
//             onChange(value);
//           }}
//           {...rest}
//         />
//       )}
//     />
//   );
// };

// export const TravelersSentDropdown = ({
//   control,
//   errors,
//   organization,
//   customAliases,
//   userPermissions,
//   setValue,
//   isSaving,
// }) => {
//   const travelers = travelerOptions.filter(option => option.value === organization.participants_sent);

//   return (
//     <Controller
//       control={control}
//       name={FIELD_NAMES['participants_sent']}
//       render={({ field: { onChange, defaultValue, value, name, ref, ...rest } }) => (
//         <DropdownSelect
//           errorMsg={''}
//           label={`# Travelers Sent on ${customAliases.alias_programs} per Year`}
//           placeholder={'Select number of travelers sent per year'}
//           defaultValue={travelers}
//           isDisabled={!userPermissions.canEdit || isSaving}
//           options={travelerOptions}
//           id={FIELD_NAMES['participants_sent']}
//           onChange={value => {
//             setValue(FIELD_NAMES['participants_sent'], value, {
//               shouldValidate: true,
//               shouldDirty: true,
//               shouldTouch: true,
//             });
//             onChange(value);
//           }}
//           {...rest}
//         />
//       )}
//     />
//   );
// };

// export const TravelerGoalDropdown = ({ control, errors, organization, userPermissions, setValue, isSaving }) => {
//   const goals = goalOptions.filter(option => option.value === organization.participants_goal);

//   return (
//     <Controller
//       control={control}
//       name={FIELD_NAMES['participants_goal']}
//       render={({ field: { onChange, defaultValue, value, name, ref, ...rest } }) => (
//         <DropdownSelect
//           errorMsg={''}
//           label={`Traveler Goal per Year`}
//           placeholder={'Select organization traveler goal per year'}
//           isDisabled={!userPermissions.canEdit || isSaving}
//           defaultValue={goals}
//           options={goalOptions}
//           id={FIELD_NAMES['participants_goal']}
//           onChange={value => {
//             setValue(FIELD_NAMES['participants_goal'], value, {
//               shouldValidate: true,
//               shouldDirty: true,
//               shouldTouch: true,
//             });
//             onChange(value);
//           }}
//           {...rest}
//         />
//       )}
//     />
//   );
// };

export const IntakeToggle = ({
  control,
  errors,
  organization,
  customAliases,
  userPermissions,
  isSaving,
  register,
  setValue,
}) => {
  return (
    <ToggleContainer>
      <Controller
        control={control}
        name={FIELD_NAMES['show_intake']}
        render={({ field: { ref, ...rest } }, register) => (
          <ToggleWrapper>
            <Toggle
              id={FIELD_NAMES['show_intake']}
              disabled={!userPermissions.canEdit || isSaving}
              onChange={value => {
                setValue(FIELD_NAMES['show_intake'], value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
              ref={register}
              {...rest}
            />
          </ToggleWrapper>
        )}
      />
    </ToggleContainer>
  );
};

export const ProgramMatchToggle = ({
  control,
  errors,
  organization,
  customAliases,
  userPermissions,
  isSaving,
  register,
  setValue,
}) => {
  return (
    <ToggleContainer>
      <Controller
        control={control}
        name={FIELD_NAMES['show_program_match']}
        render={({ field: { ref, ...rest } }, register) => (
          <ToggleWrapper>
            <Toggle
              id={FIELD_NAMES['show_program_match']}
              disabled={!userPermissions.canEdit || isSaving}
              onChange={value => {
                setValue(FIELD_NAMES['show_program_match'], value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
              ref={register}
              {...rest}
            />
          </ToggleWrapper>
        )}
      />
    </ToggleContainer>
  );
};

export const OrganizationTimezoneDropdown = ({
  control,
  errors,
  organization,
  setValue,
  userPermissions,
  isSaving,
}) => {
  const timezones = moment.tz.names();
  const timezoneOptions = timezones.map(tz => ({ value: tz, label: tz }));
  const currentTimezone = timezoneOptions.filter(option => option.value === organization.org_timezone);

  return (
    <Controller
      control={control}
      name={FIELD_NAMES['org_timezone']}
      render={({ field: { onChange, defaultValue, value, name, ref, ...rest } }) => (
        <DropdownSelect
          errorMsg={''}
          isDisabled={!userPermissions.canEdit || isSaving}
          label={`Organization Timezone`}
          placeholder={'Select organization timezone'}
          options={timezoneOptions}
          defaultValue={currentTimezone}
          id={FIELD_NAMES['org_timezone']}
          onChange={value => {
            setValue(FIELD_NAMES['org_timezone'], value, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            });
            onChange(value);
          }}
          {...rest}
        />
      )}
    />
  );
};

export const AllowTravelersToDeferProgram = ({
  control,
  errors,
  organization,
  customAliases,
  userPermissions,
  isSaving,
  register,
  setValue,
}) => {
  return (
    <ToggleContainer>
      <Controller
        control={control}
        name={FIELD_NAMES['allow_traveler_deferral']}
        render={({ field: { ref, ...rest } }, register) => (
          <ToggleWrapper>
            <Toggle
              id={FIELD_NAMES['allow_traveler_deferral']}
              disabled={!userPermissions.canEdit || isSaving}
              onChange={value => {
                setValue(FIELD_NAMES['allow_traveler_deferral'], value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
              ref={register}
              {...rest}
            />
          </ToggleWrapper>
        )}
      />
    </ToggleContainer>
  );
};

export const LimitApplicationsByTimeframe = ({
  control,
  errors,
  organization,
  customAliases,
  userPermissions,
  isSaving,
  register,
  setValue,
}) => {
  return (
    <ToggleContainer>
      <Controller
        control={control}
        name={FIELD_NAMES['limit_applications_by_timeframe']}
        render={({ field: { ref, ...rest } }, register) => (
          <ToggleWrapper>
            <Toggle
              id={FIELD_NAMES['limit_applications_by_timeframe']}
              disabled={!userPermissions.canEdit || isSaving}
              onChange={value => {
                setValue(FIELD_NAMES['limit_applications_by_timeframe'], value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
              ref={register}
              {...rest}
            />
          </ToggleWrapper>
        )}
      />
    </ToggleContainer>
  );
};

// export const AllowStudentUniverseMailer = ({
//   control,
//   errors,
//   organization,
//   customAliases,
//   userPermissions,
//   isSaving,
//   register,
//   setValue,
// }) => {
//   return (
//     <ToggleContainer>
//       <Controller
//         control={control}
//         name={FIELD_NAMES['allow_student_universe_mailer']}
//         render={({ field: { ref, ...rest } }, register) => (
//           <ToggleWrapper>
//             <Toggle
//               id={FIELD_NAMES['allow_student_universe_mailer']}
//               disabled={!userPermissions.canEdit || isSaving}
//               onChange={value => {
//                 setValue(FIELD_NAMES['allow_student_universe_mailer'], value, {
//                   shouldValidate: true,
//                   shouldDirty: true,
//                   shouldTouch: true,
//                 });
//               }}
//               ref={register}
//               {...rest}
//             />
//           </ToggleWrapper>
//         )}
//       />
//     </ToggleContainer>
//   );
// };

export const AllowTravelersToSignUp = ({
  control,
  errors,
  organization,
  customAliases,
  userPermissions,
  isSaving,
  register,
  setValue,
}) => {
  return (
    <ToggleContainer>
      <Controller
        control={control}
        name={FIELD_NAMES['allow_traveler_sign_up']}
        render={({ field: { ref,value,onChange, ...rest } }, register) => (
          <ToggleWrapper>
            <ToggleContainerCheckbox
              href="#"
              onClick={e => {
                e.preventDefault();
                setValue(FIELD_NAMES['allow_traveler_sign_up'], !value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
                onChange(!value);
              }}
            >
              <ToggleCheckBoxIcon checked={value} />
            </ToggleContainerCheckbox>
            
          </ToggleWrapper>
        )}
      />
    </ToggleContainer>
  );
};

export const ShowSignInSignUpInstruction = ({
  control,
  errors,
  organization,
  customAliases,
  userPermissions,
  isSaving,
  register,
  setValue,
  setStateValue,
}) => {
  return (
    <ToggleContainer>
      <Controller
        control={control}
        name={FIELD_NAMES['show_sign_in_instruction']}
        render={({ field: { ref,value,onChange, ...rest } }, register) => (
          <ToggleWrapper>
            <ToggleContainerCheckbox
              href="#"
              onClick={e => {
                e.preventDefault();
                setValue(FIELD_NAMES['show_sign_in_instruction'], !value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
                setStateValue(!value);
                onChange(!value);
              }}
            >
              <ToggleCheckBoxIcon checked={value} />
            </ToggleContainerCheckbox>
          </ToggleWrapper>
        )}
      />
    </ToggleContainer>
  );
};

export const SignInInstructionTextArea = ({ control, userPermissions, organization, isSaving, setValue, disabledInstruction }) => {
  
  return (
    <Controller
      control={control}
      name={FIELD_NAMES['sign_in_instruction']}
      render={({ field: { onChange, defaultValue, value, name, ref, ...rest } }) => (
        <Suspense fallback={<div>Loading...</div>}>
          <EditTextArea
            readOnly={!userPermissions.canEdit || isSaving || !disabledInstruction}
            id={FIELD_NAMES['sign_in_instruction']}
            value={value}
            charLimit={200}
            label={'Instruction'}
            placeholder={'Enter text here'}
            onChange={value => {
              setValue(FIELD_NAMES['sign_in_instruction'], value, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              });
              onChange(value);
            }}
            {...rest}
          />
        </Suspense>
      )}
    />
  );
};

export const CheckboxToggle = ({
  setStateValue,
  value
}) => {
  return (
    <ToggleContainer>
      <ToggleWrapper>
        <ToggleContainerCheckbox
          href="#"
          onClick={e => {
            e.preventDefault();
            setStateValue(!value);
          }}
        >
          <ToggleCheckBoxIcon checked={value} />
        </ToggleContainerCheckbox>
      </ToggleWrapper>
    </ToggleContainer>
  );
};