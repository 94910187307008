import React from 'react';

export default function FileUploadIcon({width = "none", height = "none"}) {
  return (  
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
    <g clip-path="url(#clip0_3858_17838)">
    <path d="M13.866 11.5853C13.6647 11.5856 13.4659 11.6311 13.2845 11.7186C13.1031 11.806 12.9437 11.9331 12.818 12.0905L4.62207 22.3341H16.607C16.9633 22.3341 17.3051 22.4756 17.557 22.7276C17.809 22.9796 17.9506 23.3213 17.9506 23.6777C17.9506 24.7467 18.3752 25.7719 19.1312 26.5279C19.8871 27.2838 20.9123 27.7085 21.9814 27.7085C23.0504 27.7085 24.0757 27.2838 24.8316 26.5279C25.5875 25.7719 26.0122 24.7467 26.0122 23.6777C26.0122 23.3213 26.1537 22.9796 26.4057 22.7276C26.6577 22.4756 26.9994 22.3341 27.3558 22.3341H39.3407L31.1447 12.0905C31.0191 11.9331 30.8596 11.806 30.6782 11.7186C30.4968 11.6311 30.2981 11.5856 30.0967 11.5853H13.866ZM40.6144 25.0213H28.565C28.2566 26.5399 27.4327 27.9053 26.2329 28.886C25.033 29.8667 23.531 30.4024 21.9814 30.4024C20.4317 30.4024 18.9297 29.8667 17.7299 28.886C16.53 27.9053 15.7061 26.5399 15.3977 25.0213H3.34834L4.20825 31.9059C4.2489 32.2312 4.40713 32.5305 4.65313 32.7473C4.89912 32.9641 5.2159 33.0834 5.54378 33.0829H38.419C38.7464 33.0828 39.0625 32.9631 39.3079 32.7464C39.5534 32.5296 39.7112 32.2308 39.7518 31.9059L40.6117 25.0213H40.6144ZM10.7193 10.411C11.0971 9.93866 11.5764 9.55744 12.1216 9.29554C12.6668 9.03365 13.2639 8.8978 13.8687 8.89807H30.094C30.6988 8.8978 31.296 9.03365 31.8412 9.29554C32.3863 9.55744 32.8656 9.93866 33.2434 10.411L43.1861 22.8393C43.2978 22.9795 43.3803 23.1406 43.4287 23.3132C43.4772 23.4858 43.4906 23.6664 43.4682 23.8443L42.4202 32.2391C42.2983 33.2142 41.8244 34.1113 41.0876 34.7615C40.3507 35.4118 39.4017 35.7704 38.419 35.77H5.54378C4.56104 35.7704 3.61202 35.4118 2.87517 34.7615C2.13832 34.1113 1.6644 33.2142 1.54255 32.2391L0.494538 23.8443C0.472513 23.6662 0.48637 23.4856 0.535292 23.3129C0.584213 23.1403 0.667208 22.9793 0.779381 22.8393L10.722 10.411H10.7193Z" fill="#0D6EFD"/>
    </g>
    <defs>
    <clipPath id="clip0_3858_17838">
    <rect width="42.9952" height="42.9952" fill="white" transform="translate(0.476562 0.837219)"/>
    </clipPath>
    </defs>
    </svg>
  );
}
