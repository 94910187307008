import React, { useState } from 'react';
import { compose } from 'recompose';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import {
  RegisterPlanMySelfButton,
  RegisterPlanOnBehalfButton,
} from '../../../../../../styledComponents/styles/Action.buttons.styled';

import { AccentButtonMediumNunito } from '../../../../../../styledComponents/styles/Buttons.styled';
import StyledIcon from '../../../../../../styledComponents/styles/Icon.styled';
import s from './registerTravelPlanModal.scss';
import BaseModalHeader from '../../../../../../components/baseModal/BaseModalHeader';
import locationIcon from '../../../../../../shared/images/location.png';
import { Modal } from 'react-bootstrap';
import { useSelector } from "react-redux";

export const enhance = compose(withStyles(s));

const RegisterTravelPlanModal = ({ show, onClose }) => {
  const [planType, setPlanType] = useState('');
  const { is_proxy_user } = useSelector(state => state.profile);

  return (
    <Modal show={show} onHide={onClose} centered size="lg">
      <BaseModalHeader heading="Register Travel Plan" onClose={onClose} />

      <div className={s.flexContainer}>
        <main className={s.main}>
          <div className={s.title}>Who are you creating this plan for?</div>
          <div className={s.buttonDiv}>
            <div className={s.buttonContainer}>
              <RegisterPlanMySelfButton
                onClick={() => {
                  setPlanType('myself');
                }}
              />
            </div>

            <div className={s.buttonContainer}>
              <RegisterPlanOnBehalfButton
                disabled={!is_proxy_user}
                onClick={() => {
                  setPlanType('onbehalf');
                }}
              />
            </div>
          </div>
          <main className={s.nextContainer}>
            <AccentButtonMediumNunito
              disabled={!planType}
              onClick={() => {
                if (planType === 'myself') {
                  window.location.href = '/traveler/create-solo-plan';
                }

                if (planType === 'onbehalf') {
                  window.location.href = '/traveler/create-solo-plan?type=onbehalf';
                }
              }}
            >
              Next
              <StyledIcon type="ChevronForward" color="#fff" />
            </AccentButtonMediumNunito>
          </main>
        </main>
        <div
          className={s.informationSection}
        >
          <img src={locationIcon} alt={'Register Travel'} />
        </div>
      </div>
    </Modal>
  );
};

export default enhance(RegisterTravelPlanModal);
