import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import toastMessageStyles from '../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import TextInput from '../../styledComponents/styles/TextInput.styled';
import PrimaryButton from '../PrimaryButton';
import { updateProxy, createProxyUser, reinviteProxyUser, fetchProxyUsers } from '../../actions/proxyManagmentActions';
import BaseModalHeader from '../baseModal/BaseModalHeader';


const inputLabelStyle = {
  fontWeight: "400",
  color: "var(--Theme-Dark, #373737)",
  fontFamily: "Open Sans",
  fontSize: "16px",
  fontStyle: "normal",
  lineHeight: "120%", // 19.2px
  letterSpacing: "0.08px",
}

const customErrorStyle = {
  color: "var(--error-text, #8B1313)",
  fontFamily: "Open Sans",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "150%", // 21px
};


function InviteSingleProxyUserModal({ show, handleClose, selectedRowData, isReinvite=false, userType="client" }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); 
  const { control, handleSubmit, formState: { errors }  } = useForm({
    defaultValues: {
      first_name: selectedRowData?.first_name ? selectedRowData?.first_name : "",
      last_name: selectedRowData?.last_name ? selectedRowData?.last_name : "",
      email: selectedRowData?.email ? selectedRowData?.email : "",
      university_id: selectedRowData?.universityID ? selectedRowData?.universityID : "",
    },
  });

  let headerTitle = userType==="client" ?  "Invite Proxy" : "Invite New Proxy User";
  let modalText = userType==="client" ? "Enter the details of the new proxy and invite." : "Proxy users can create and edit travel plans for you. They can also modify any forms on your behalf, as long as those forms have not been submitted. Enter their details below to add a new proxy user.";
  let buttonText = "Invite Proxy";

  if(selectedRowData?.invitation_id && selectedRowData?.user_id===null && isReinvite===true) {
    headerTitle = "Re-Invite Proxy";
    modalText = "Edit the details of the selected proxy and re-invite.";
    buttonText = "Re-Invite";
  } else if(selectedRowData?.user_id && isReinvite===false) {
    headerTitle = "Edit Proxy";
    modalText = "Edit the details of the selected proxy.";
    buttonText = "Save";
  } 


  const onSubmit = data => {
    
    if(userType==="client" && selectedRowData?.invitation_id && selectedRowData?.user_id===null && isReinvite===true) {
      setLoading(true);
      const payload = {
        "invitation_id": selectedRowData?.invitation_id,
        "traveler_invitation": {
            "email": data.email,
            "first_name": data.first_name,
            "last_name": data.last_name,
            "proxy_invite": true,
            "university_id": data.university_id
        }
      }
      dispatch(
        reinviteProxyUser(payload, (status, message) => {
          if (status) {
            toast("Proxy reinvited", {
              type: 'success',
              className: toastMessageStyles['successContainer'],
              toastClassName: toastMessageStyles['wrapper'],
              bodyClassName: toastMessageStyles['successBody'],
              progressClassName: toastMessageStyles['progress-bar'],
              position: 'bottom-right'
            });
            setLoading(false);
            dispatch(fetchProxyUsers());
            handleClose();
          } else {
            setLoading(false);
            toast(message, {
              type: 'error',
              className: toastMessageStyles['failureContainer'],
              toastClassName: toastMessageStyles['wrapper'],
              bodyClassName: toastMessageStyles['failureBody'],
              progressClassName: toastMessageStyles['progress-bar'],
              position: 'bottom-right'
            });
          }
        }),
      );
    }

    else if(userType==="client" && selectedRowData?.user_id && isReinvite===false) {
      setLoading(true);
      const payload = {
        "user": {
            "first_name": data.first_name,
            "last_name": data.last_name,
            "university_id": data.university_id
        }
      }

      dispatch(
        updateProxy(selectedRowData?.user_id, payload, (status, message) => {
          if (status) {
            toast("Proxy Edited", {
              type: 'success',
              className: toastMessageStyles['successContainer'],
              toastClassName: toastMessageStyles['wrapper'],
              bodyClassName: toastMessageStyles['successBody'],
              progressClassName: toastMessageStyles['progress-bar'],
              position: 'bottom-right'
            });
            setLoading(false);
            dispatch(fetchProxyUsers());
            handleClose();
          } else {
            setLoading(false);
            toast(message, {
              type: 'error',
              className: toastMessageStyles['failureContainer'],
              toastClassName: toastMessageStyles['wrapper'],
              bodyClassName: toastMessageStyles['failureBody'],
              progressClassName: toastMessageStyles['progress-bar'],
              position: 'bottom-right'
            });
          }
        }),
      );


    } 
    
    else {
      setLoading(true);
      const payload = {
        "traveler_invitation": {
          "email": data.email,
          "first_name": data.first_name,
          "last_name": data.last_name,
          "proxy_invite": true,
          "university_id": data.university_id
        }
      }
      
      dispatch(
        createProxyUser(payload,userType, (status, message) => {
          if (status) {
            toast(userType==="client" ? "Proxy Invited" : "Proxy added", {
              type: 'success',
              className: toastMessageStyles['successContainer'],
              toastClassName: toastMessageStyles['wrapper'],
              bodyClassName: toastMessageStyles['successBody'],
              progressClassName: toastMessageStyles['progress-bar'],
              position: 'bottom-right'
            });
            setLoading(false);
            if(userType==='client') {
              dispatch(fetchProxyUsers());
            }
            handleClose();
          } else {
            if(message.includes("An account already exists for this email address")) {
              message = "Proxy already exists"
            }
            toast(message, {
              type: 'error',
              className: toastMessageStyles['failureContainer'],
              toastClassName: toastMessageStyles['wrapper'],
              bodyClassName: toastMessageStyles['failureBody'],
              progressClassName: toastMessageStyles['progress-bar'],
              position: 'bottom-right'
            });
            setLoading(false);
          }
        }),
      );
    }   
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered size={'md'}>
        <>
          <BaseModalHeader heading={headerTitle} onClose={handleClose} />
          <Modal.Body className={styles.modalBody}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Container className={styles.container}>
                <div className={styles.text}>
                  {/* Enter the details of the new proxy and invite. */}
                  {modalText}
                </div>

                <div>
                  <Controller
                    name="first_name"
                    control={control}
                    rules={{ required: "Enter the proxy’s First Name" }}
                    render={({ field }) => (
                      <TextInput
                        label="First Name"
                        placeholder="Enter First Name"
                        required
                        {...field}
                        customLabelStyle={inputLabelStyle}
                        placeholderFontStyle={"Normal"}
                        errorMsg={errors.first_name?.message}
                        showErrorIcon={false}
                        customErrorStyle={customErrorStyle}
                        placeholderFontSize={"16px"}
                        placeholderFontColor={"#6C757D"}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="last_name"
                    control={control}
                    rules={{ required: "Enter the proxy’s Last Name" }}
                    render={({ field }) => (
                      <TextInput
                        label="Last Name"
                        placeholder="Enter Last Name"
                        required
                        {...field}
                        errorMsg={errors.last_name?.message}
                        showErrorIcon={false}
                        customLabelStyle={inputLabelStyle}
                        placeholderFontStyle={"Normal"}
                        customErrorStyle={customErrorStyle}
                        placeholderFontSize={"16px"}
                        placeholderFontColor={"#6C757D"}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="email"
                    control={control}
                    
                    rules={{
                      required: "Enter the proxy’s Email Address",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Enter a valid email",
                      },
                    }}
                    render={({ field }) => (
                      <TextInput
                        label="Email"
                        placeholder="Enter Email"
                        disabled={selectedRowData?.user_id && !isReinvite ? true : false}
                        required
                        {...field}
                        errorMsg={errors.email?.message}
                        showErrorIcon={false}
                        customLabelStyle={inputLabelStyle}
                        placeholderFontStyle={"Normal"}
                        customErrorStyle={customErrorStyle}
                        placeholderFontSize={"16px"}
                        placeholderFontColor={"#6C757D"}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="university_id"
                    control={control}
                    rules={{ required: "Enter the proxy’s University ID" }}
                    render={({ field }) => (
                      <TextInput
                        label="University ID"
                        placeholder="Enter University ID"
                        required
                        {...field}
                        errorMsg={errors.university_id?.message}
                        showErrorIcon={false}
                        customLabelStyle={inputLabelStyle}
                        placeholderFontStyle={"Normal"}
                        customErrorStyle={customErrorStyle}
                        placeholderFontSize={"16px"}
                        placeholderFontColor={"#6C757D"}
                      />
                    )}
                  />
                </div>
              </Container>
              <div className={styles.footer}>
                <div className={styles.buttonContainer}>
                  <button type="button" className={styles.cancelButton} onClick={handleClose}>Cancel</button>
                  <PrimaryButton text={buttonText} loading={loading} type={"submit"} />
                </div>
              </div>
            </form>
          </Modal.Body>
        </>       
      </Modal>
    </>
  );
}

export default withStyles(styles)(InviteSingleProxyUserModal);
