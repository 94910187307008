exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _1rsbt{to{-webkit-transform:rotate(1turn)}}@keyframes _1rsbt{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._21CIH{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;width:100%}._21CIH ._2oNqa,._21CIH .DLeRs{-ms-flex:1 1;flex:1 1}._21CIH ._2oNqa{padding-top:12px}._21CIH ._2oNqa p{font-family:AvenirNext,sans-serif}._21CIH ._3clEm{-ms-flex:1 1;flex:1 1;padding-top:12px;background-color:#ebebeb;border-radius:12px;padding-left:12px;margin-bottom:20px}._21CIH ._3clEm h2{padding:20px 0}._21CIH ._3XyoG{display:-ms-flexbox;display:flex;height:56px;padding:16px;-ms-flex-align:start;align-items:flex-start;-ms-flex-negative:0;flex-shrink:0;border-radius:4px;border:1px solid #a9cada;border:1px solid var(--Components-Alert-Primary-Border,#a9cada);background:#deecff;background:var(--Components-Alert-Info-Background,#deecff);margin-top:20px;margin-bottom:10px}._21CIH ._2dlFS{color:#255a94;color:var(--info-text,#255a94);font-family:Open Sans;font-size:16px;font-style:normal;font-weight:400;line-height:150%}", ""]);

// exports
exports.locals = {
	"ov-details": "_21CIH",
	"ov-details-headline": "DLeRs",
	"ov-details-description": "_2oNqa",
	"ov-details-program": "_3clEm",
	"ov-details-banner": "_3XyoG",
	"ov-details-banner-text": "_2dlFS",
	"spin": "_1rsbt"
};