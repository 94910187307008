import React from 'react';
import styled, { css } from 'styled-components';
import {
  ApplicationIcon,
  FacultyIcon,
  FacultyDisabledIcon,
  GeneralFormIcon,
  LuggageIcon,
  LuggageDisabledIcon,
  RecommendationIcon,
  PlanFormIcon,
  SignInIcon,
  SignUpIcon,
} from './IconCustom.styled';

const Selected = css`
  background-color: ${({ theme }) => theme.actionButton.active.backgroundColor};
  border: ${({ theme }) => theme.actionButton.active.border};
`;

const Button = styled.button.attrs(props => ({
  type: props.type || 'button',
}))`
  background-color: ${({ theme }) => theme.actionButton.backgroundColor};
  box-shadow: ${({ theme }) => theme.actionButton.boxShadow};
  border: 1px solid ${({ theme }) => theme.actionButton.backgroundColor};
  border-radius: ${({ theme }) => theme.actionButton.borderRadius};
  color: ${({ theme }) => theme.actionButton.fontColor};
  display: inline-block;
  font-family: ${({ theme }) => theme.actionButton.fontFamily};
  font-size: ${({ theme }) => theme.actionButton.fontSize};
  height: ${({ theme }) => theme.actionButton.height};
  line-height: ${({ theme }) => theme.actionButton.lineHeight};
  padding: ${({ theme }) => theme.actionButton.padding};
  text-align: initial;
  width: ${({ theme }) => theme.actionButton.width};

  img {
    align-self: center;
    display: inline-flex;
    margin-right: 18px;
    position: relative;
  }

  &:hover {
    background-color: ${({ theme }) => theme.actionButton.hover.backgroundColor};
    border: ${({ theme }) => theme.actionButton.hover.border};
    cursor: pointer;
  }

  &:active {
    background-color: ${({ theme }) => theme.actionButton.active.backgroundColor};
    border: ${({ theme }) => theme.actionButton.active.border};
  }

  &:disabled,
  &:disabled:active {
    background-color: ${({ theme }) => theme.actionButton.disabled.backgroundColor};
    box-shadow: ${({ theme }) => theme.actionButton.disabled.boxShadow};
    cursor: not-allowed;
  }

  &:focus {
    border: ${({ theme }) => theme.actionButton.focus.border};
    outline-color: ${({ theme }) => theme.actionButton.focus.outlineColor};
  }

  ${({ selected }) => (selected ? Selected : null)};
`;

export function FacultyAccessButton(props) {
  return (
    <Button aria-label="Administrator access button" {...props}>
      {props.disabled ? <FacultyDisabledIcon size={'24px'} /> : <FacultyIcon size={'24px'} />}
      Administrator access
    </Button>
  );
}

export function TravelerAccessButton(props) {
  return (
    <Button aria-label="Traveler access button" {...props}>
      {props.disabled ? <LuggageDisabledIcon size={'24px'} /> : <LuggageIcon size={'24px'} />}
      Traveler access
    </Button>
  );
}

export function SignInButton(props) {
  return (
    <Button aria-label="Sign in button" {...props}>
      <SignInIcon size={'24px'} />
      Sign In
    </Button>
  );
}

export function SignUpButton(props) {
  return (
    <Button aria-label="Sign up button" {...props}>
      <SignUpIcon size={'24px'} />
      Sign Up
    </Button>
  );
}

export function GeneralFormButton(props) {
  return (
    <Button aria-label="General Forms button" {...props}>
      <GeneralFormIcon size={'24px'} />
      General Forms
    </Button>
  );
}

export function ApplicationButton(props) {
  return (
    <Button aria-label="Application button" {...props}>
      <ApplicationIcon size={'24px'} />
      Application
    </Button>
  );
}

export function RecommendationButton(props) {
  return (
    <Button aria-label="Recommendation button" {...props}>
      <RecommendationIcon size={'24px'} />
      Recommendation
    </Button>
  );
}
export function PlanFormButton(props) {
  return (
    <Button aria-label="Plan Form button" {...props}>
      <PlanFormIcon size={'24px'} />
        Plan Form
    </Button>
  );
}
export function AutomationFormButton(props) {
  return (
    <Button aria-label="Automation Forms button" {...props}>
      <GeneralFormIcon size={'24px'} />
      Form
    </Button>
  );
}


export function RegisterPlanMySelfButton(props) {
  return (
    <Button aria-label="Automation Forms button" {...props}>
      <GeneralFormIcon size={'24px'} />
      For Myself
    </Button>
  );
}


export function RegisterPlanOnBehalfButton(props) {
  return (
    <Button aria-label="Application button" {...props}>
      <ApplicationIcon size={'24px'} />
      On Behalf of a Traveler
    </Button>
  );
}
