import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';

import { Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import pdfIcon from './pdfIcon.png';
import closeIcon from './closeIcon.png';
import { useDispatch, useSelector } from 'react-redux';
import { downloadBatchZip, downloadBatchZipTraveler } from '../../actions/formsActions';
import toastMessageStyles from '../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { toast } from 'react-toastify';
import { getStandardReportNotifications } from '../../actions/reportsActions';


function BatchDownloadPdfModal({ show, handleClose, submissionIds = [], planIds = [], type = '', action="" }) {
  const dispatch = useDispatch();
  const { batchZipDownload } = useSelector(state => state.forms);
  const customAliases = useSelector(state => state.profile.customAliases);
  const [checkedAppFormResponses, setCheckedAppFormResponses] = useState(true);
  const [checkedUploadedFiles, setCheckedUploadedFiles] = useState(false);
  const [checkedRecommendation, setCheckedRecommendation] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
      if (checkedRecommendation === false && checkedUploadedFiles===false && checkedAppFormResponses===false) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
  }, [checkedAppFormResponses,checkedUploadedFiles, checkedRecommendation]);

  const getUniquePlanIdsCount = () => {
    const uniquePlanIds = new Set(planIds);
    return uniquePlanIds.size;
  };

  const getModalTextMessage = () => {
    let message = `You are about to download the content of <strong>${
      submissionIds.length
    }</strong> ${type.toLowerCase()}${
      submissionIds?.length > 1 ? 's' : ''
    }. Customize your download to include what you need.`;

    if (type === 'Plan Forms') {
      message = `You are about to download the content of ${submissionIds.length} Plan Form${
        submissionIds.length > 1 ? '(s)' : ''
      } ON ${getUniquePlanIdsCount()} Travel Plan${
        getUniquePlanIdsCount() > 1 ? 's' : ''
      }. Customize your download to include what you need.`;
    }

    return message;
  };

  function CustomToastMessage() {
    return (
      <div
        style={{
          color: '#FFF',
          fontFamily: 'Open Sans',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
          letterSpacing: '-0.64px',
        }}
      >
        Zip file is generating.{' '}
        <a
          href="/reports/downloads"
          target="_blank"
          style={{
            color: '#fff',
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
            letterSpacing: '-0.64px',
            textDecorationLine: 'underline',
          }}
        >
          View Downloads
        </a>
      </div>
    );
  }

  const downloadPDF = () => {

    if(action==="traveler") {
      let payload = {
        submission_ids: submissionIds,
        plan_id: planIds[0],
        response_downloadable: checkedAppFormResponses,
        upload_files: checkedUploadedFiles,
        type: type,
      };
      
      dispatch(
        downloadBatchZipTraveler(payload, (status, message) => {
          if (status) {
            toast(<CustomToastMessage />, {
              type: 'success',
              className: toastMessageStyles['successContainer'],
              toastClassName: toastMessageStyles['wrapper'],
              bodyClassName: toastMessageStyles['successBody'],
              progressClassName: toastMessageStyles['progress-bar'],
            });
            dispatch(getStandardReportNotifications());
            handleClose();
          } else {
            toast('Something went wrong, please try again later', {
              type: 'error',
              className: toastMessageStyles['failureContainer'],
              toastClassName: toastMessageStyles['wrapper'],
              bodyClassName: toastMessageStyles['failureBody'],
              progressClassName: toastMessageStyles['progress-bar'],
            });
          }
        }),
      );
    } else {
      let payload = {
        submission_ids: submissionIds,
        response_downloadable: checkedAppFormResponses,
        upload_files: checkedUploadedFiles,
        recommendation: checkedRecommendation ? checkedRecommendation : false,
        type: type,
      };
      dispatch(
        downloadBatchZip(payload, (status, message) => {
          if (status) {
            toast(<CustomToastMessage />, {
              type: 'success',
              className: toastMessageStyles['successContainer'],
              toastClassName: toastMessageStyles['wrapper'],
              bodyClassName: toastMessageStyles['successBody'],
              progressClassName: toastMessageStyles['progress-bar'],
            });
            dispatch(getStandardReportNotifications());
            handleClose();
          } else {
            toast('Something went wrong, please try again later', {
              type: 'error',
              className: toastMessageStyles['failureContainer'],
              toastClassName: toastMessageStyles['wrapper'],
              bodyClassName: toastMessageStyles['failureBody'],
              progressClassName: toastMessageStyles['progress-bar'],
            });
          }
        }),
      );
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <div className={styles.title}>
            <h1 className={styles.headingtext}>Download PDF</h1>
            <a
              href="#"
              role="button"
              onClick={e => {
                e.preventDefault();
                handleClose();
              }}
            >
              <img src={closeIcon} alt="Close" />
            </a>
          </div>
        </Modal.Header>

        <Modal.Body>
          <Container>
            <Row>
              <Col xs={3} className={styles.pdfIconContainer}>
                <img src={pdfIcon} className={styles.pdfIcon} />
              </Col>
              <Col>
                {/* <span className={styles.text}>{getModalTextMessage()}</span> */}
                <span
                  className={styles.text}
                  dangerouslySetInnerHTML={{ __html: getModalTextMessage() }}
                ></span>
                <p className={styles.colpcontent}>
                  Once ready, your zip file can be found in <a href="/reports/downloads">downloads</a>.
                </p>

                <div>
                  <input
                    type="checkbox"
                    checked={checkedAppFormResponses}
                    onChange={e => setCheckedAppFormResponses(e.target.checked)}
                  />{' '}
                  <span className={styles.checkLabel}>{type} Responses</span>
                </div>

                <div>
                  <input
                    type="checkbox"
                    checked={checkedUploadedFiles}
                    onChange={e => setCheckedUploadedFiles(e.target.checked)}
                  />{' '}
                  <span className={styles.checkLabel}>Uploaded Files by {customAliases?.alias_travelers}</span>
                </div>
                {/* {type !== 'Plan Forms' && */}
                  <div>
                    <input
                      type="checkbox"
                      checked={checkedRecommendation}
                      onChange={e => setCheckedRecommendation(e.target.checked)}
                    />{' '}
                    <span className={styles.checkLabel}>Associated Recommendation(s)</span>
                  </div>
                {/* } */}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className={styles.footercolor}>
          <Button
            onClick={downloadPDF}
            className={styles.downloadPdfButton}
            type="button"
            disabled={batchZipDownload?.loading || disableButton}
          >
            {batchZipDownload?.loading ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Loading...
              </>
            ) : (
              <>Download PDF</>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default withStyles(styles)(BatchDownloadPdfModal);
